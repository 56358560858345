<template>
  <div>
    <div class="page-title">
      <h3>Режактирование кластера</h3>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Вернуться'"
           @click="$router.push('/clusters')">
          <i class="material-icons">arrow_back</i>
        </a>
      </span>
    </div>

    <Loader v-if="loading"/>

    <form class="form" @submit.prevent="submitHandler">
      <div class="row">
        <div class="input-field">
          <div class="input-field">
            <input id="id" type="text" v-model="id" disabled>
            <label for="id">ID</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <input id="name" type="text" v-model="name" :class="{invalid: $v.name.$error}">
          <label for="name">Название</label>
          <small class="helper-text invalid" v-if="$v.name.$error">Не указано название</small>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <div class="input-field">
            <select id="macro_region_id" ref="macro_region_select" v-model="macro_region_id"
                    :class="{invalid: $v.macro_region_id.$error}">
              <option value="" disabled selected>Выбрать</option>
              <option v-for="r in macro_region_list" :key="r.id" :value="r.id">{{ r.name }}</option>
            </select>
            <label for="macro_region_id">Макро-регион</label>
            <small class="helper-text invalid" v-if="$v.macro_region_id.$error">Не выбран
              макро-регион</small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <input id="supervisor" type="text" v-model="supervisor">
          <label for="supervisor">Руководитель</label>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <input id="senior_engineer" type="text" v-model="senior_engineer">
          <label for="senior_engineer">Главный инженер</label>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <input id="ito_engineer" type="text" v-model="ito_engineer">
          <label for="ito_engineer">ИТО инженер</label>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <input id="senior_ito_engineer" type="text" v-model="senior_ito_engineer">
          <label for="senior_ito_engineer">Главный ИТО инженер</label>
        </div>
      </div>

      <button class="btn waves-effect waves-light blue darken-4" type="submit">
        Сохранить
        <i class="material-icons right">send</i>
      </button>
    </form>
  </div>
</template>

<script>
import {required, numeric, decimal} from 'vuelidate/lib/validators'
import dateFilter from '../../filters/date.filter'
import convertDateString from '../../utils/date'

export default {
  metaInfo() {
    return {
      title: 'Редактирование кластера'
    }
  },
  data: () => ({
    loading: true,
    name: '',
    macro_region_id: null,
    macro_region_select: null,
    macro_region_list: null,
    supervisor: '',
    senior_engineer: '',
    ito_engineer: '',
    senior_ito_engineer: '',
  }),
  validations: {
    name: {required},
    macro_region_id: {required},
  },
  async mounted() {
    const cluster = await this.$store.dispatch('fetchObjectClusterById', this.$route.params.id)
    this.macro_region_list = await this.$store.dispatch('fetchObjectMacroRegions')

    this.id = cluster.id
    this.name = cluster.name
    this.macro_region_id = cluster.macro_region.id
    this.supervisor = cluster.supervisor || ''
    this.senior_engineer = cluster.senior_engineer || ''
    this.ito_engineer = cluster.ito_engineer || ''
    this.senior_ito_engineer = cluster.senior_ito_engineer || ''

    this.loading = false

    setTimeout(() => {
      this.macro_region_select = M.FormSelect.init(this.$refs.macro_region_select)
      M.updateTextFields()
    })
  },
  methods: {
    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const cluster = {
        id: this.id,
        name: this.name,
        macro_region: {id: this.macro_region_id},
        supervisor: this.supervisor,
        senior_engineer: this.senior_engineer,
        ito_engineer: this.ito_engineer,
        senior_ito_engineer: this.senior_ito_engineer,
      }

      await this.$store.dispatch('updateObjectCluster', cluster)
      await this.$router.push('/clusters')
    },
  },
  destroyed() {
    if (this.macro_region_select && this.macro_region_select.destroy) {
      this.macro_region_select.destroy()
    }
  },
}
</script>
